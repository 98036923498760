@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83% 53.3%;
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.5% 48%;
  }
}

/* Custom styles for tabs on mobile */
@layer components {
  .mobile-tab-container {
    @apply overflow-x-auto scrollbar-none -mx-4 px-4;
  }

  .mobile-tab-list {
    @apply inline-flex min-w-max py-1;
  }

  .mobile-tab {
    @apply px-3 py-2 text-sm font-medium border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap transition-colors;
  }

  .mobile-tab-active {
    @apply border-blue-600 text-blue-600;
  }
}

/* Add custom scrollbar styles */
.scrollbar-thin::-webkit-scrollbar {
    width: 6px;
}
  
.scrollbar-thin::-webkit-scrollbar-track {
    background-color: rgba(243, 244, 246, 1); /* bg-gray-100 */
    border-radius: 9999px;
}
  
.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(209, 213, 219, 1); /* bg-gray-300 */
    border-radius: 9999px;
}
  
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: rgba(156, 163, 175, 1); /* bg-gray-400 */
}

/* Hide scrollbar for tab navigation on mobile while preserving functionality */
.scrollbar-none::-webkit-scrollbar {
    display: none;
}

.scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
}