/* We'll keep these for reference but we're using inline styles for transitions now */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.slide-exit {
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 300ms ease-in-out;
}

/* New animation classes */
.slide-in-right {
  animation: slideInRight 300ms forwards;
}

.slide-out-left {
  animation: slideOutLeft 300ms forwards;
}

.slide-in-left {
  animation: slideInLeft 300ms forwards;
}

.slide-out-right {
  animation: slideOutRight 300ms forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* Step transition animations */
.step-container {
  width: 100%;
  transition: transform 300ms ease-in-out;
}

/* Slide from right animation */
.slide-from-right {
  animation: slideFromRight 300ms ease-in-out forwards;
}

/* Slide from left animation */
.slide-from-left {
  animation: slideFromLeft 300ms ease-in-out forwards;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* These help ensure the animation looks smoother */
.step-container {
  position: relative;
  will-change: transform;
}

/* Keep these for reference, but we're using the animation approach now */
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: all 300ms;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: all 300ms;
}
