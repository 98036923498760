@keyframes float {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, -15px) rotate(3deg); }
  50% { transform: translate(-5px, -25px) rotate(-2deg); }
  75% { transform: translate(-15px, -15px) rotate(-4deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes fadeInOut {
  0% { opacity: 0.7; }
  50% { opacity: 0.95; }
  100% { opacity: 0.7; }
}

@keyframes blob {
  0% { transform: scale(1); }
  33% { transform: scale(1.1); }
  66% { transform: scale(0.9); }
  100% { transform: scale(1); }
}

@keyframes printPaper {
  0% { transform: translateY(15px); opacity: 0; }
  30% { transform: translateY(0); opacity: 1; }
  70% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-5px); opacity: 1; }
}

.animation-delay-2 {
  animation-delay: 2s;
}

.animate-blob {
  animation: blob 7s infinite;
}

.animate-printPaper {
  animation: printPaper 4s infinite;
}

/* Floating papers container styling - improved for better visibility */
.floating-papers-container {
  perspective: 1000px;
  transform-style: preserve-3d;
}

.floating-papers-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: -1;
}

.floating-papers-container .paper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  background: linear-gradient(to bottom right, white, #f8f9fa);
}

/* Responsive adjustments for floating papers */
@media (max-width: 768px) {
  .floating-papers-container .paper {
    /* Larger papers for mobile but still appropriate for screen size */
    max-width: 60px !important;
    max-height: 70px !important;
    opacity: 0.9 !important;
  }
}
